import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import { Link } from "react-router-dom";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";
const AboutSix = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);

  return (
    <div className="section section-padding-top technology-section-padding-bottom-180">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
            <div className="about-image-area">
              <div className="about-image">
                <Tilt scale={scale} transitionSpeed={4000}>
                  {/* <img
                    src={process.env.PUBLIC_URL + "images/about/about-5.jpg"}
                    alt=""
                  /> */}
                  <img
                    // style={{ height: "270px", width: "370px" }}
                    src={process.env.PUBLIC_URL + "images/about/services1.jpg"}
                    alt=""
                  />
                </Tilt>
              </div>
              <div className="about-image">
                <Tilt scale={scale} transitionSpeed={4000}>
                  {/* <img
                    src={process.env.PUBLIC_URL + "images/about/about-6.jpg"}
                    alt=""
                  /> */}
                  <img
                    src={process.env.PUBLIC_URL + "images/about/ok.jpg"}
                    alt=""
                  />
                </Tilt>
              </div>
              <div className="shape shape-1" id="scene" ref={sceneEl}>
                <span data-depth="1">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "images/shape-animation/about-shape-1.png"
                    }
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>

          <div
            className="col-xl-5 col-lg-6 col-12"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="about-content-area">
              <SectionTitleTwo
                subTitle="Innovative &amp; cutting-edge technology"
                title="We use latest technologies that are proven and practical"
              />

              <p>
                Our goal is to make the process easier for you. We specializes
                in advising stakeholders on strategy, site assessment, optimal
                designs, procurement, quality assurance and supervision in
                implementation and operation of renewable energy assets.{" "}
              </p>

              <Link
                className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4"
                to={process.env.PUBLIC_URL + "/contact"}
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSix;
