// import PropTypes from "prop-types";
// import { useState } from "react";
// import CountUp from "react-countup";
// import VisibilitySensor from "react-visibility-sensor";

// const CounterUpItem = ({ data }) => {
//   const [didViewCountUp, setDidViewCountUp] = useState(false);

//   const onVisibilityChange = (isVisible) => {
//     if (isVisible) {
//       setDidViewCountUp(true);
//     }
//   };
//   console.log(data);
//   return (
//     <div className="funfact">
//       <div className="number">
//         <VisibilitySensor
//           onChange={onVisibilityChange}
//           offset={{ top: 10 }}
//           delayedCall
//         >
//           <CountUp end={didViewCountUp ? data.number : 0} />
//         </VisibilitySensor>

//         {data.id === 3 || (data.id === 4 && <text>+</text>)}
//         {data.id === 4 && (
//           <span
//             style={{
//               color: "teal",
//               fontSize: "18px",
//             }}
//           >
//             GW
//           </span>
//         )}
//       </div>
//       <h6 className="text">{data.text}</h6>
//     </div>
//   );
// };

// CounterUpItem.propTypes = {
//   data: PropTypes.object,
// };

// export default CounterUpItem;

import PropTypes from "prop-types";
import { useState } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";

const CounterUpItem = ({ data }) => {
  const [didViewCountUp, setDidViewCountUp] = useState(false);

  const onVisibilityChange = (isVisible) => {
    if (isVisible) {
      setDidViewCountUp(true);
    }
  };

  return (
    <div className="funfact">
      <div className="number">
      {data.id === 3 && <span>~</span>}
        <VisibilitySensor
          onChange={onVisibilityChange}
          offset={{ top: 10 }}
          delayedCall
        >
          {data.id === 4 ? (
            <CountUp end={didViewCountUp ? data.number : 0} decimals={1} />
          ) : (
            <CountUp end={didViewCountUp ? data.number : 0} />
          )}
        </VisibilitySensor>
        {(data.id != 3 && <text>+</text>)}
        {data.id === 4 && (
          <span
            style={{
              color: "teal",
              fontSize: "18px",
            }}
          >
            GW
          </span>
        )}
      </div>
      <h6 className="text">{data.text}</h6>
    </div>
  );
};

CounterUpItem.propTypes = {
  data: PropTypes.object,
};

export default CounterUpItem;
