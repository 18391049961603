import PropTypes from "prop-types";
import React from "react";
import ReactVivus from "react-vivus";
import { Link } from "react-router-dom";
// import servicesData from '../../data/services/services.json'
// import Lottie from "lottie-react";
// import animationData from './test.json'
const IconBox = ({ data, classOption, homePage }) => {
  console.log(homePage);
  return (
    <div className={`icon-box text-center ${classOption}`}>
      <div className="icon">
        <ReactVivus
          id={`servicesvg-${data.id}`}
          option={{
            file: data.icon,
            animTimingFunction: "EASE",
            type: "oneByOne",
            delay: 80,
          }}
        />

        {/* <Lottie animationData={animationData} autoplay={false} /> */}
      </div>
      <div className="content">
        <h3 className="title">{data.title}</h3>
        <div className="desc">
          <p>{data.desc}</p>
        </div>

        {!homePage && (
          
          <Link
            to={process.env.PUBLIC_URL + `/service/${data.id}`}
            className="link"
          >
            {data.pageLink}
          </Link>
        )}
      </div>
    </div>
  );
};

IconBox.propTypes = {
  data: PropTypes.object,
  classOption: PropTypes.string,
  homePage: PropTypes.bool,
};

IconBox.defaultProps = {
  classOption: "icon-box text-center",
};

export default IconBox;
