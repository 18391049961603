import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { useToasts } from "react-toast-notifications";
const ProjectForm = () => {
  const { addToast } = useToasts();
  const { register, errors } = useForm({
    mode: "onBlur",
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.message) {
      addToast("Ensure all fields are filled out.", {
        appearance: "warning",
      });
    } else {
      const formElement = document.querySelector("form");

      // emailjs
      //   .sendForm(
      //     "service_htcgup5",
      //     "template_384rz4h",
      //     formElement,
      //     "7odDyhydalI5F4Fd8"
      //   )
      emailjs
      .sendForm(
        "service_mwgywjt",
        "template_ie1ug8c",
        formElement,
        "d4CSIB9N4Kgback49"
      )
        .then(
          (result) => {
            console.log(result.text);
            addToast("Form has been submitted", {
              appearance: "success",
            });
            setFormData({ name: "", email: "", message: "" });
          },
          (error) => {
            // console.error(error.text);
            addToast(error.text, {
              appearance: "error",
            });
          }
        );
    }
  };

  return (
    <Fragment>
      <form onSubmit={onSubmit}>
        <div className="row mb-n4">
          <div className="col-md-12 col-12 mb-4">
            <input
              type="text"
              placeholder="Your Name *"
              name="name"
              value={formData.name}
              onChange={handleChange}
              ref={register({ required: "Name is required" })}
            />
            {errors.name && <p>{errors.name.message}</p>}
          </div>
          <div className="col-md-12 col-12 mb-4">
            <input
              type="email"
              placeholder="Email *"
              name="email"
              value={formData.email}
              onChange={handleChange}
              ref={register({
                required: "Email is required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "invalid email address",
                },
              })}
            />
            {errors.email && <p>{errors.email.message}</p>}
          </div>

          <div className="col-12 mb-6">
            <textarea
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
              ref={register({ required: "Message is required" })}
            ></textarea>
            {errors.message && <p>{errors.message.message}</p>}
          </div>
          <div className="col-12 text-center mb-4">
            <button className="btn btn-primary btn-hover-secondary">
              Get consultation
            </button>
          </div>
        </div>
      </form>
      <p className="form-messege"></p>
    </Fragment>
  );
};

export default ProjectForm;
