import React from "react";
import YoutubeBackground from "react-youtube-background";
const IntroTwo = () => {
  return (
    <YoutubeBackground
      // videoId="yYNO29KQJy0"
      aspectRatio={"16:10"}
      videoId="Qj2WD-YkbFo"
      overlay="rgba(0,0,0,.4)"
      className="intro-section section bg-video"
    >
      <div className="container">
        <div className="row row-cols-lg-1 row-cols-1">
          <div className="col align-self-center">
            <div className="intro-content-two text-center mt-xl-8 mt-lg-8 mt-md-8 mt-sm-8 mt-xs-8">
              <h2 className="title">Revolutionizing Energy Solutions</h2>
              <div className="desc">
                <p>
                  {/* Tawanai EnergyTech provides expert renewable energy
                  consultancy and solutions. We offer tailored strategies, from
                  site prospecting to operational optimization.With global
                  experience, we ensure efficient, sustainable power generation,
                  making us a dependable, data-driven partner in renewable
                  energy projects */}
                  Tawanai EnergyTech provides expert renewable energy
                  consultancy and solutions. We offer tailored strategies, from
                  site prospecting to operational optimization. Our global
                  experience ensures efficient and sustainable power generation,
                  making us a dependable, data-driven partner in renewable
                  energy projects
                </p>
              </div>
              {/* <Link
                to={process.env.PUBLIC_URL + "/"}
                className="btn btn-primary btn-hover-secondary"
              >
                Get Started
              </Link>
              <Link
                to={process.env.PUBLIC_URL + "/"}
                className="btn btn-outline-white btn-hover-primary"
              >
                {" "}
                Learn More{" "}
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </YoutubeBackground>
  );
};

export default IntroTwo;

// import React from "react";
// // import YoutubeBackground from "react-youtube-background";
// import video from '../../assets/images/video/test.mp4'
// import { Link } from "react-router-dom";
// const IntroTwo = () => {
//   return (
//     <div style={{
//       position: 'relative',
//       width: '100%',
//       paddingBottom: '56.25%', // Maintains a 16:9 aspect ratio
//     }}>
//       <video
//         autoPlay
//         loop
//         src={video} // Ensure this is the correct path to your video file
//         style={{
//           position: 'absolute',
//           top: 0,
//           left: 0,
//           width: '100%',
//           height: '100%',
//           objectFit: 'cover' // Covers the full area without losing aspect ratio
//         }}
//       >
//       <div style={{
//         position: 'absolute',
//         top: 0,
//         left: 0,
//         width: '100%',
//         height: '100%',
//         display: 'flex',
//         flexDirection: 'column', // Aligns children vertically
//         justifyContent: 'center', // Centers content vertically
//         padding: '5% 10%', // Responsive padding (smaller top/bottom padding)
//       }}>
//         <div className="container">
//           <div className="row row-cols-lg-1 row-cols-1">
//             <div className="col align-self-center">
//               <div className="intro-content-two text-center" style={{ maxWidth: '100%' }}>
//                 <h2 className="title" style={{ color: 'white', textShadow: '2px 2px 4px rgba(0,0,0,0.5)' }}>
//                   Revolutionizing Energy Solutions
//                 </h2>
//                 <div className="desc">
//                   <p style={{
//                     color: 'white',
//                     textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
//                     fontSize: 'clamp(12px, 2.5vw, 16px)' // Responsive font size
//                   }}>
//                     Tawanai EnergyTech provides expert renewable energy
//                     consultancy and solutions. We offer tailored strategies, from
//                     site prospecting to operational optimization. With global
//                     experience, we ensure efficient, sustainable power generation,
//                     making us a dependable, data-driven partner in renewable
//                     energy projects.
//                   </p>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       </video>
//     </div>
//   );
// };

// export default IntroTwo;
