import { useState, useEffect, useRef } from "react";
import SectionTitleTwo from "../../components/SectionTitles/SectionTitleTwo";
import { Link } from "react-router-dom";
import Tilt from "react-parallax-tilt";
import Parallax from "parallax-js";

const AboutFive = () => {
  const [scale] = useState(1.04);
  const sceneEl = useRef(null);

  useEffect(() => {
    const parallaxInstance = new Parallax(sceneEl.current, {
      relativeInput: true,
    });

    parallaxInstance.enable();

    return () => parallaxInstance.disable();
  }, []);
  return (
    <div className="section section-padding-top section-padding-bottom-180">
      <div className="container">
        <div className="row">
          <div className="col-xl-7 col-lg-6 col-12" data-aos="fade-up">
            <div className="about-image-area">
              <div className="about-image">
                <Tilt scale={scale} transitionSpeed={4000}>
                  {/* <img
                    src={process.env.PUBLIC_URL + "images/about/about-3.jpg"}
                    alt=""
                  /> */}
                  <img
                    src={process.env.PUBLIC_URL + "images/about/about-3.jpg"}
                    alt=""
                  />
                </Tilt>
              </div>
              <div className="about-image">
                <Tilt scale={scale} transitionSpeed={4000}>
                  <img
                    src={process.env.PUBLIC_URL + "images/about/about-4.jpg"}
                    alt=""
                  />
                </Tilt>
              </div>

              <div className="shape shape-1" id="scene" ref={sceneEl}>
                <span data-depth="1">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "images/shape-animation/about-shape-1.png"
                    }
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>

          <div
            className="col-xl-5 col-lg-6 col-12"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            <div className="about-content-area">
              {/* <SectionTitleTwo 
                                subTitle="Our solutions begin with brand research"
                                title="We’ve worked alongside plenty of brands &amp; startups"
                            /> */}
              <SectionTitleTwo
                subTitle="Know Everything about Tawanai."
                // title="We’ve worked alongside plenty of brands &amp; startups "
                title="We’ve worked alongside a diverse range of clients &amp; organizations "
              />

              {/* <p>Our team of designers and developers are perfectionists who love what they do – we thrive off pushing the boundaries of our clients’ expectations and our own capabilities.</p> */}
              <p>
                Tawanai EnergyTech Private Limited was founded in 2017 as a
                dedicated service provider for all aspects of a wind farm, from
                analytical support in project development to supervision in
                installation & commissioning and subsequent asset management.
              </p>
              <br />
              <p>
                Over the years, serving clients worldwide and domestically, We
                have become a trusted, data-driven consultancy excelling in
                markets and leveraging cutting-edge technologies such as Data
                Analytics, Optimization Algorithms, and Cloud Computing.
              </p>

              <Link
                className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4"
                to={process.env.PUBLIC_URL + "/contact"}
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFive;
