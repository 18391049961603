import React from "react";
import SEO from "../components/SEO";
import Header from "../partials/header/Header";
import IntroTwo from "../container/IntroSlider/IntroTwo";
// import HomeAboutTwo from "../components/About/HomeAboutTwo.jsx";
// import HomeAboutThree from '../container/About/HomeAboutThree';
import Funfact from "../container/Funfact/Funfact";
import ServiceIconBox from "../container/service/ServiceIconBox";
import HomeSkillWithVideo from "../container/HomeSkillWithVideo/HomeSkillWithVideo";
// import TestimonialContainer from "../container/Testimonial/TestimonialContainer";
// import HomeBlog from "../container/BlogGrid/HomeBlog";
// import ContactInformationTwo from "../container/ContactInformation/ContactInformationTwo";
// import BrandContainer from "../container/Brand/BrandContainer";
import Footer from "../container/Footer/Footer";
import ScrollToTop from "../components/ScrollToTop.jsx";
import HomeAbout from "../components/About/HomeAbout.jsx";
// import IntroThree from "../container/IntroSlider/IntroThree.js";
import ContactInformation from "../container/ContactInformation/ContactInformation.js";
import SnapShotHome from "./SnapShotHome.jsx";

const HomeTwo = () => {
  return (
    <React.Fragment>
      <SEO title="Tawanai || EnergyTech" />
      <Header />
      <IntroTwo />
      {/* <IntroThree/> */}
      {/* <HomeAboutTwo /> */}
      <HomeAbout />
      <Funfact />
      <ServiceIconBox classOption="bg-color-1" />
      <HomeSkillWithVideo />
      {/* <PortfolioTwo /> */}
      {/* <TestimonialContainer /> */}
      <SnapShotHome />
      {/* <HomeBlog SectionBgColor="bg-primary-blue" /> */}
      {/* <ContactInformationTwo /> */}
      <ContactInformation />
      {/* <BrandContainer classOption="section-padding-bottom" /> */}
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};

export default HomeTwo;
