// import PropTypes from "prop-types";
// import React from "react";
// import Qvideo from "../../pages/Qvideo.mp4";
// import CFDVideo from "../../pages/CFD.mp4";
// // import { Link } from "react-router-dom";

// const ProductsPageData = [
//   {
//     name: "Micrositing Optimization",
//     text: `
//             The app incorporates advanced features for conducting obstacle and terrain assessments, which are crucial
//             for planning and optimizing site layouts. By complying with IEC61400-2019 standards, QuantaWind
//             provides a reliable framework for identifying potential challenges and making informed decisions regarding
//             site development and turbine placement.
//             `,
//   },
//   {
//     name: "Sensor Correction",
//     text: `
//             The app provides robust solutions for identifying and correcting data gaps, a common issue in data
//             collection at both minor and major timescales. This automated process ensures continuous data flow,
//             enhancing analysis accuracy. Details on how QuantaWind approaches these corrections are readily
//             available in the in-app FAQs, offering users insights into the sophisticated algorithms behind the scenes.
//             `,
//   },
//   {
//     name: "Mast Data Analysis",
//     text: `
//             The ability to compare wind speed and direction sensors directly within the app streamlines the process of
//             sensor performance evaluation. This feature supports the detailed analysis of sensor data, enabling users to
//             make informed decisions about sensor placement, maintenance, and data reliability.
//             `,
//   },
//   {
//     name: "Long-Term Data Analysis",
//     text: `
//                 With access to long-term reference data and the ability to employ machine-learning algorithms for data
//             correction and analysis, QuantaWind channels leading technological innovations in data analysis. These
//             features enable users to enhance the accuracy of their long-term projections and optimize their projects
//             based on advanced analytical techniques.
//                 `,
//   },
//   {
//     name: "Computational Fluid Dynamics",
//     text: ` Quantawind leverages Computational Fluid Dynamics (CFD) to simulate wind flow over both simple and complex
//             terrains, This powerful tool allows for detailed analysis of wind behavior, enabling precise
//             planning and optimization of turbine placement. By visualizing how wind interacts with various topographies,
//             users can identify potential challenges and optimize energy capture and turbine efficiency.
//                 `,
//   },
// ];

// const WorkDetailsContainer = ({ data }) => {
//   return (
//     <div className="section section-padding">
//       <div className="container">
//         <div className="row pt--100 pb--80">
//           <div className="col-lg-4 col-md-6 col-12">
//             <div className="work-left work-details" data-aos="fade-up">
//               <div className="portfolio-main-info">
//                 {/* <h2 className="title" style={{paddingBottom:"20%"}}>About Quantawind</h2> */}
//                 <video
//                   src={Qvideo}
//                   className="w-100"
//                   loop
//                   autoPlay
//                   muted
//                   style={{ marginTop: "20%" }}
//                 />
//                 <video
//                   src={CFDVideo}
//                   className="w-100"
//                   loop
//                   autoPlay
//                   muted
//                   style={{ marginTop: "2%" }}
//                 />
//               </div>
//             </div>
//           </div>

//           <div className="col-lg-7 col-md-6 offset-lg-1 col-12">
//             <div className="work-left work-details mt-6">
//               <div className="work-main-info">
//                 <div className="work-content">
//                   <h6 className="title" data-aos="fade-up">
//                     ABOUT THE PROJECT
//                   </h6>

//                   <div className="desc mt-8">
//                     {data.body.map((value, i) => {
//                       return (
//                         <div
//                           key={i}
//                           className="content mb-5"
//                           data-aos="fade-up"
//                           dangerouslySetInnerHTML={{ __html: value }}
//                         />
//                       );
//                     })}

//                     <div className="work-btn" style={{ marginTop: "15%" }}>
//                       <h2
//                       // className="btn btn-primary btn-hover-secondary"
//                       // to={process.env.PUBLIC_URL + data.btn.link}
//                       >
//                         Features Of QuantaWind
//                       </h2>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         {ProductsPageData.map((f, i) => {
//           return (
//             <div className="row mt-lg-12 mt-12 " key={i}>
//               <div className="col-lg-4 col-md-12 col-12">
//                 <div className="digital-marketing" data-aos="fade-up">
//                   <h3 style={{ fontSize: "20px" }}>{f.name}</h3>
//                 </div>
//               </div>
//               <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
//                 <div
//                   className="digital-marketing mt-lg-0 mt-6"
//                   data-aos="fade-up"
//                 >
//                   <div className="inner">
//                     <p>{f.text}</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           );
//         })}

//         <div className="custom-layout-gallery mt-lg-20 mt-12">
//           <div className="row">
//             {/* <div className="col-lg-12 my-6">
//               <div className="thumbnail" data-aos="fade-up">
//                 <img
//                   className="w-100"
//                   src={`${process.env.PUBLIC_URL}/${data.gallery.imageThree}`}
//                   alt="Agency"
//                 />
//               </div>
//             </div> */}

//             <div className="col-lg-6 col-md-6 col-12">
//               <div
//                 className="thumbnail"
//                 data-aos="fade-up"
//                 style={{ marginBottom: "2%" }}
//               >
//                 <img
//                   className="w-100"
//                   src={`${process.env.PUBLIC_URL}/${data.gallery.imageOne}`}
//                   alt="Agency"
//                 />
//               </div>
//             </div>
//             <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-10">
//               <div
//                 className="thumbnail"
//                 data-aos="fade-up"
//                 style={{ marginBottom: "2%" }}
//               >
//                 <img
//                   className="w-100"
//                   src={`${process.env.PUBLIC_URL}/${data.gallery.imageTwo}`}
//                   alt="Agency"
//                 />
//               </div>
//             </div>
//             <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-10">
//               <div className="thumbnail" data-aos="fade-up">
//                 <img
//                   className="w-100"
//                   src={`${process.env.PUBLIC_URL}/${data.gallery.imageThree}`}
//                   alt="Agency"
//                 />
//               </div>
//             </div>
//             <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-10">
//               <div className="thumbnail" data-aos="fade-up">
//                 <img
//                   className="w-100"
//                   src={`${process.env.PUBLIC_URL}/${data.gallery.imageFour}`}
//                   alt="Agency"
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// WorkDetailsContainer.propTypes = {
//   data: PropTypes.object,
// };

// export default WorkDetailsContainer;

import PropTypes from "prop-types";
import React from "react";
import Qvideo from "../../pages/Qvideo.mp4";
import CFDVideo from "../../pages/CFD.mp4";
import microStingVideo from "../../pages/Qvideo.mp4";
// import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import QuantawindVideo from "../../pages/QwVideo1.mp4";

const ProductsPageData = [
  {
    name: "Micrositing Optimization",
    text: `
            The app incorporates advanced features for conducting obstacle and terrain assessments, which are crucial
            for planning and optimizing site layouts. By complying with IEC61400-2019 standards, QuantaWind
            provides a reliable framework for identifying potential challenges and making informed decisions regarding
            site development and turbine placement.
            `,
  },
  {
    name: "Sensor Correction",
    text: `
            The app provides robust solutions for identifying and correcting data gaps, a common issue in data
            collection at both minor and major timescales. This automated process ensures continuous data flow,
            enhancing analysis accuracy. Details on how QuantaWind approaches these corrections are readily
            available in the in-app FAQs, offering users insights into the sophisticated algorithms behind the scenes.
            `,
  },
  {
    name: "Mast Data Analysis",
    text: `
            The ability to compare wind speed and direction sensors directly within the app streamlines the process of
            sensor performance evaluation. This feature supports the detailed analysis of sensor data, enabling users to
            make informed decisions about sensor placement, maintenance, and data reliability.
            `,
  },
  {
    name: "Long-Term Data Analysis",
    text: `
                With access to long-term reference data and the ability to employ machine-learning algorithms for data
            correction and analysis, QuantaWind channels leading technological innovations in data analysis. These
            features enable users to enhance the accuracy of their long-term projections and optimize their projects
            based on advanced analytical techniques.
                `,
  },
  {
    name: "Computational Fluid Dynamics",
    text: ` Quantawind leverages Computational Fluid Dynamics (CFD) to simulate wind flow over both simple and complex
            terrains, This powerful tool allows for detailed analysis of wind behavior, enabling precise
            planning and optimization of turbine placement. By visualizing how wind interacts with various topographies,
            users can identify potential challenges and optimize energy capture and turbine efficiency.
                `,
  },
];

const WorkDetailsContainer = ({ data }) => {
  return (
    <div className="section section-padding">
      <div className="container">
        <div className="row pt--100 pb--80">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="work-left work-details" data-aos="fade-up">
              <div className="portfolio-main-info">
                {/* <h2 className="title" style={{paddingBottom:"20%"}}>Quantawind</h2> */}

                <video
                  src={QuantawindVideo}
                  className="w-100"
                  loop
                  autoPlay
                  muted
                />
                {/* <video
                  src={Qvideo}
                  className="w-100"
                  loop
                  autoPlay
                  muted
                  style={{ marginTop: "20%" }}
                /> */}
                {/* <video
                  src={CFDVideo}
                  className="w-100"
                  loop
                  autoPlay
                  muted
                  style={{ marginTop: "2%" }}
                /> */}
              </div>
            </div>
          </div>

          <div className="col-lg-7 col-md-6 offset-lg-1 col-12">
            <div className="work-left work-details mt-6">
              <div className="work-main-info">
                <div className="work-content">
                  <h6 className="title" data-aos="fade-up">
                    ABOUT THE PROJECT
                  </h6>

                  <div className="desc mt-8">
                    {data.body.map((value, i) => {
                      return (
                        <div
                          key={i}
                          className="content mb-5"
                          data-aos="fade-up"
                          dangerouslySetInnerHTML={{ __html: value }}
                        />
                      );
                    })}

                    <div className="work-btn" style={{ marginTop: "15%" }}>
                      <h2
                      // className="btn btn-primary btn-hover-secondary"
                      // to={process.env.PUBLIC_URL + data.btn.link}
                      >
                        Features Of QuantaWind
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {ProductsPageData.map((f, i) => {
          return (
            <div className="row mt-lg-12 mt-12 " key={i}>
              <div className="col-lg-4 col-md-12 col-12">
                <div className="digital-marketing" data-aos="fade-up">
                  <h3 style={{ fontSize: "20px" }}>{f.name}</h3>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                <div
                  className="digital-marketing mt-lg-0 mt-6"
                  data-aos="fade-up"
                >
                  <div className="inner">
                    <p>{f.text}</p>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="custom-layout-gallery mt-lg-20 mt-12">
          <div className="row">
            {/* <div className="col-lg-12 my-6">
              <div className="thumbnail" data-aos="fade-up">
                <img
                  className="w-100"
                  src={`${process.env.PUBLIC_URL}/${data.gallery.imageThree}`}
                  alt="Agency"
                />
              </div>
            </div> */}

            <Carousel
              additionalTransfrom={0}
              arrows
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              pauseOnHover
              renderArrowsWhenDisabled={false}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
              responsive={{
                desktop: {
                  breakpoint: {
                    max: 3000,
                    min: 1024,
                  },
                  items: 1,
                },
                mobile: {
                  breakpoint: {
                    max: 464,
                    min: 0,
                  },
                  items: 1,
                },
                tablet: {
                  breakpoint: {
                    max: 1024,
                    min: 464,
                  },
                  items: 1,
                },
              }}
              rewind={false}
              rewindWithAnimation={false}
              rtl={false}
              shouldResetAutoplay
              showDots
              sliderClass=""
              slidesToSlide={1}
              swipeable
            >
              {/* <img
                src="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
                style={{
                  display: "block",
                  height: "70%",
                  margin: "auto",
                  width: "100%",
                }}
              /> */}

              <video
                src={CFDVideo}
                autoPlay
                muted
                loop
                // controls
                style={{
                  display: "block",
                  height: "90%",
                  margin: "auto",
                  width: "100%",
                }}
              />

              <video
                src={microStingVideo}
                autoPlay
                muted
                loop
                // controls
                style={{
                  display: "block",
                  height: "90%",
                  margin: "auto",
                  width: "100%",
                }}
              />
            </Carousel>

            <div className="col-lg-6 col-md-6 col-12">
              <div
                className="thumbnail"
                data-aos="fade-up"
                style={{ marginBottom: "2%", marginTop: "10%" }}
              >
                <img
                  className="w-100"
                  src={`${process.env.PUBLIC_URL}/${data.gallery.imageOne}`}
                  alt="Agency"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-10">
              <div
                className="thumbnail"
                data-aos="fade-up"
                style={{ marginBottom: "2%", marginTop: "10%" }}
              >
                <img
                  className="w-100"
                  src={`${process.env.PUBLIC_URL}/${data.gallery.imageTwo}`}
                  alt="Agency"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-10">
              <div className="thumbnail" data-aos="fade-up">
                <img
                  className="w-100"
                  src={`${process.env.PUBLIC_URL}/${data.gallery.imageThree}`}
                  alt="Agency"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-10">
              <div className="thumbnail" data-aos="fade-up">
                <img
                  className="w-100"
                  src={`${process.env.PUBLIC_URL}/${data.gallery.imageFour}`}
                  alt="Agency"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WorkDetailsContainer.propTypes = {
  data: PropTypes.object,
};

export default WorkDetailsContainer;
