import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import imageFive from "./5.png";
import imageSeven from "./7.png";
import imageEight from "./8.png";
import imageNine from "./9.gif";
import imageTen from "./10.gif";
import imageEleven from "./11.jpeg";
import imageTwelve from "./12.jpeg";
import imageThirteen from "./13.jpeg";
import Qvideo from "./Qvideo.mp4";
import ltc from "./ltc.mp4";
import imageFourteen from "./14.jpeg";
// import QcVideo from "./QcVideo.mp4";
import CFDVideo from "./CFD.mp4";
import QuantawindVideo from "./QwVideo1.mp4";

const SnapShotHome = () => {
  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={3000}
      centerMode={true}
      className=""
      containerClass="container"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 3000,
            min: 1024,
          },
          items: 1,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: 1,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: 1,
        },
      }}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots
      sliderClass=""
      slidesToSlide={1}
      swipeable
    >
      <video
        src={QuantawindVideo}
        autoPlay
        muted
        loop
        style={{
          display: "block",
          height: "60%",
          margin: "auto",
          width: "100%",
        }}
      ></video>
      <video
        src={CFDVideo}
        autoPlay
        muted
        loop
        style={{
          display: "block",
          height: "60%",
          margin: "auto",
          width: "100%",
        }}
      ></video>
      <video
        src={Qvideo}
        autoPlay
        muted
        loop
        style={{
          display: "block",
          height: "60%",
          margin: "auto",
          width: "100%",
        }}
      ></video>
      <video
        src={ltc}
        autoPlay
        muted
        loop
        style={{
          display: "block",
          height: "60%",
          margin: "auto",
          width: "100%",
        }}
      ></video>

      <img
        src={imageTen}
        style={{
          display: "block",
          height: "60%",
          margin: "auto",
          width: "100%",
        }}
      />

      <img
        src={imageFive}
        style={{
          display: "block",
          height: "60%",
          margin: "auto",
          width: "100%",
        }}
      />
      <img
        src={imageSeven}
        style={{
          display: "block",
          height: "60%",
          margin: "auto",
          width: "100%",
        }}
      />
      <img
        src={imageTwelve}
        style={{
          display: "block",
          height: "60%",
          margin: "auto",
          width: "100%",
        }}
      />
      <img
        src={imageFourteen}
        style={{
          display: "block",
          height: "60%",
          margin: "auto",
          width: "70%",
        }}
      />

      {/* <img
        src={imageEight}
        style={{
          display: "block",
          height: "60%",
          margin: "auto",
          width: "100%",
        }}
      />
      <img
        src={imageNine}
        style={{
          display: "block",
          height: "60%",
          margin: "auto",
          width: "100%",
        }}
      /> */}
    </Carousel>
  );
};

export default SnapShotHome;
