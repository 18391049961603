import React from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";

const Btn = (props) => {
  return (
    <React.Fragment>
      {/* <Link to={process.env.PUBLIC_URL + "/"} className="btn btn-light btn-hover-primary">{props.name}</Link> */}
      <a
        href="https://www.linkedin.com/company/tawanai-energytech/"
        target="blank"
        className="btn btn-light btn-hover-primary"
        style={{ color: 'black' }}
      >
        <i className="fab fa-linkedin" style={{ marginRight: "10%", color: 'black' }}></i>{" "}
        {props.name}
      </a>
    </React.Fragment>
  );
};

Btn.propTypes = {
  name: PropTypes.string,
};

export default Btn;
