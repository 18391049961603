// import React from "react";
// import SectionTitle from "../../components/SectionTitles/SectionTitle";
// import WorkData from "../../data/work/workDetails.json";
// import WorkItemTwo from "../../components/Work/WorkItemTwo.jsx";

// const WorkContainer = () => {
//   return (
//     <div className="section section-padding-t90-b100">
//       <div className="container">
//         <SectionTitle
//           headingOption="title fz-32"
//           title="We offers tailored solutions for optimal wind farm <br/> performance  and renewable energy integration."
//         />

//         <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 mb-n6">
//           {WorkData &&
//             WorkData.map((single, key) => {
//               return (
//                 <div
//                   key={key}
//                   className="col mb-6"
//                   data-aos="fade-up"
//                   data-aos-delay="300"
//                 >
//                   <WorkItemTwo
//                     classOption="box-border"
//                     data={single}
//                     key={key}
//                   />
//                 </div>
//               );
//             })}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default WorkContainer;

import React from "react";
import SectionTitle from "../../components/SectionTitles/SectionTitle";
import WorkData from "../../data/work/workDetails.json";
import WorkItemTwo from "../../components/Work/WorkItemTwo.jsx";

const WorkContainer = () => {
  return (
    <div className="section section-padding-t90-b100">
      <div className="container">
        <SectionTitle
          headingOption="title fz-32"
          title="We offer tailored solutions for optimal wind farm <br/> performance and renewable energy integration."
        />

        <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 mb-n6">
          {WorkData &&
            WorkData.map((single, key) => {
              // Determine whether to disable pointer events based on key value
              const disablePointerEvents = key >= 1;

              return (
                <div
                  key={key}
                  className="col mb-6"
                  style={{
                    opacity: disablePointerEvents ? 0.5 : 1, // Reduce opacity if pointer events are disabled
                    pointerEvents: disablePointerEvents ? "none" : "auto", // Disable pointer events conditionally
                    transition: "opacity 0.3s ease", // Optional: Add transition effect
                  }}
                  data-aos="fade-up"
                  data-aos-delay="300"
                >
                  <WorkItemTwo
                    classOption="box-border"
                    data={single}
                    key={key}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default WorkContainer;
