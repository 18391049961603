import React from "react";
import SEO from "../components/SEO";
import PropTypes from "prop-types";
import Header from "../partials/header/Header.jsx";
import Breadcrumb from "../container/Breadcrumb/Breadcrumb";
import Footer from "../container/Footer/Footer";
import ServicesData from "../data/services/services.json";
import ScrollToTop from "../components/ScrollToTop.jsx";
import ServiceDetailsContainer from "../container/ServiceCategory/serviceDetailsContainer.js";
const ServiceCategory = ({
  match: {
    params: { id },
  },
}) => {
  const ServiceId = parseInt(id, 10);
  const data = ServicesData.filter((services) => services.id === ServiceId);

  return (
    <React.Fragment>
      <SEO title="Tawanai || EnergyTech" />
      <Header />
      <Breadcrumb
        // image="images/bg/breadcrumb-bg-two.jpg"
        image="images/bg/bg1.webp"
        // title={data[0]?.title}
        title={"Tawanai EnergyTech"}
        content="Home"
        contentTwo="service"
      />
    
      <ServiceDetailsContainer data={data[0]} />
      <Footer />
      <ScrollToTop />
    </React.Fragment>
  );
};
ServiceCategory.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }),
};

export default ServiceCategory;
