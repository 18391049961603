import React from 'react'

const GoogleMap = () => {
    return (
        <div className="google-map-area section text-center section-padding-bottom">
            <div className="container">
                <div className="contact-map-area" data-aos="fade-up">
                    {/* <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2136.986005919501!2d-73.9685579655238!3d40.75862446708152!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258e4a1c884e5%3A0x24fe1071086b36d5!2sThe%20Atrium!5e0!3m2!1sen!2sbd!4v1585132512970!5m2!1sen!2sbd"  aria-hidden="false"></iframe> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.6383511194663!2d77.1188033752622!3d28.550588075709584!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1dd4186868c1%3A0xc2d4946cf9eb5ac6!2sCOWRKS%20Aerocity%2C%20Worldmark%201%20Tower%20A!5e0!3m2!1sen!2sin!4v1711962654294!5m2!1sen!2sin"  aria-hidden="false" ></iframe>
                </div>
            </div>
        </div>
    )
}

export default GoogleMap;
