// import React, { useState } from "react";
import { Link } from "react-router-dom";
// import ModalVideo from "react-modal-video";

const HomeSkillWithVideo = () => {
  // const [isOpen, setOpen] = useState(false);

  return (
    <div className="video-section section section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-5 col-lg-6 col-12" data-aos="fade-up">
            <div className="skill-with-video-content">
              <div className="section-title-two mb-8">
                <span className="sub-title">
                  Tailored, innovative solutions for you
                </span>
                <h3 className="title">
                  We offer the tools and expertise that your company requires
                </h3>
              </div>

              <ul className="agency-list">
                <li className="item">
                  <div className="icon">
                    <i className="fas fa-circle "></i>
                  </div>
                  {/* <div className="text">Select &amp; customize courses to your preferences</div> */}
                  <div className="text">
                    In depth mast analysis using{" "}
                    <Link to={"/products/1"}>QuantaWind</Link>
                  </div>
                </li>
                {/* <li className="item">
                  <div className="icon">
                    <i className="fas fa-circle"></i>
                  </div>
              
                  <div className="text">
                    Weather forecasting services using QuantaWeather
                  </div>
                </li>
                <li className="item">
                  <div className="icon">
                    <i className="fas fa-circle"></i>
                  </div>
                
                  <div className="text">
                    Cutting-edge SCADA mining using QuantaSCADA
                  </div>
                </li> */}
                <li className="item">
                  <div className="icon">
                    <i className="fas fa-circle"></i>
                  </div>
                  <div className="text">
                    Wind project management using QuantaSlide
                  </div>
                </li>
                <li className="item">
                  <div className="icon">
                    <i className="fas fa-circle"></i>
                  </div>
                  <div className="text">
                    Preliminary wind site identification with precision
                  </div>
                </li>

                <li className="item">
                  <div className="icon">
                    <i className="fas fa-circle"></i>
                  </div>
                  <div className="text">
                    International standards in data processing and analysis
                  </div>
                </li>
                <li className="item">
                  <div className="icon">
                    <i className="fas fa-circle"></i>
                  </div>
                  <div className="text">
                    Comprehensive wind resource analysis using custom Tawanai
                    models
                  </div>
                </li>
                <li className="item">
                  <div className="icon">
                    <i className="fas fa-circle"></i>
                  </div>
                  <div className="text">
                    Extensive project management, supervisory, monitoring, and
                    audit services as Owner’s Engineer for wind farms
                  </div>
                </li>
              </ul>
              <Link
                to={process.env.PUBLIC_URL + "/products"}
                className="btn btn-primary btn-hover-secondary"
              >
                Discover Our Products
              </Link>
            </div>
          </div>

          <div className="offset-xl-1 col-xl-6 col-lg-6 col-12">
            <div className="video-popup-area">
              <div className="skill-video">
                {/* <img
                  className="image"
                  src={process.env.PUBLIC_URL + "/images/video/skill-video.jpg"}
                  alt="video popup"
                /> */}
                <img
                  className="image"
                  src={
                    process.env.PUBLIC_URL +
                    "/images/video/skill-with-video.jpeg"
                  }
                  alt="video popup"
                />

                {/* <button className="icon" onClick={() => setOpen(true)}>
                  <i className="fas fa-play"></i>
                </button> */}
                {/* <ModalVideo
                  channel="youtube"
                  autoplay
                  isOpen={isOpen}
                  videoId="eS9Qm4AOOBY"
                  onClose={() => setOpen(false)}
                /> */}
              </div>

              <div className="shape shape-1 scene">
                <span data-depth="1">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/images/shape-animation/video-shape-1.png"
                    }
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSkillWithVideo;
