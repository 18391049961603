import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import QWvideo from "../../pages/QwVideo.mp4";
console.log(QWvideo);
const WorkItemTwo = ({ data }) => {
  return (
    <div className="work">
      <div className="thumbnail">
        <Link
          className="image"
          style={{display:"flex",justifyContent: "center",alignItems: "center",
          borderTop: "1px solid #E7E7E7",
          borderRight: "1px solid #E7E7E7",
          borderLeft: "1px solid #E7E7E7",
          borderBottom: "none"

          }}
          to={process.env.PUBLIC_URL + `/products/${data.id}`}
        >
          {data.id > 1 ? (
            <img src={process.env.PUBLIC_URL + data.image} alt="work" />
          ) : (
            <video
              src={process.env.PUBLIC_URL + data.image}
              autoPlay
              loop
              muted
              style={{ width: "269px", height: "275px",}}
            ></video>
            
          )}
          {/* <img src={process.env.PUBLIC_URL + data.image} alt="work" /> */}
        </Link>
      </div>
      <div className="info">
        <h3 className="title" style={{ display: "inline-block" }}>
          <Link to={process.env.PUBLIC_URL + `/products/${data.id}`}>
            {data.title}
          </Link>
          {data.id > 1 && (
            <p style={{ display: "inline-block" }}> - coming soon</p>
          )}
        </h3>
        <p className="desc">{data.excerpt}</p>
        <Link to={process.env.PUBLIC_URL + `/products/${data.id}`}>
          View Project
        </Link>
      </div>
    </div>
  );
};

WorkItemTwo.propTypes = {
  data: PropTypes.object,
};

export default WorkItemTwo;
